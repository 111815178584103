import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
    padding: 20px;
    background-color: #f4f4f4;
`;

const Title = styled.h1`
    font-size: 24px;
    color: #333;
`;



function Home() {
    return (
        <Container>
            <Title>Welcome to My Website</Title>
            <p>This is a simple React app styled with Styled Components.</p>
        </Container>
    );
}

export default Home;
