import React from 'react';

function Contact() {
    return (
        <div>
            <h2>Contact Me</h2>
            <p>You can reach me at myemail@example.com.</p>
        </div>
    );
}

export default Contact;
