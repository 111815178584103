import React from 'react';

function About() {
    return (
        <div>
            <h2>About Me</h2>
            <p>Here is some information about me.</p>
        </div>
    );
}

export default About;
